import React from 'react'
import { Link } from "gatsby"
import { kebabCase } from 'lodash'

class BlogPost extends React.Component {
  render() {
    const post = this.props.post

    return (
      <div className="overflow-hidden bg-white mb-8 pb-6"> 
        <Link to={post.fields.slug}>
          {/* {post.frontmatter.featuredimage ? (
            <div>
              <PreviewCompatibleImage
                imageInfo={{
                  image: post.frontmatter.featuredimage,
                  style: { maxHeight: '260px' },
                  alt: `featured image thumbnail for post ${
                    post.title
                  }`
                }}
              />
            </div>
          ) : null} */}
          <div className="px-6 py-4">
            <div className="">
              <div className="text-sm text-gray-500 tracking-widest">
                {post.frontmatter.date}
              </div>
            </div>
            <div className="pt-2">
              <div className="font-bold text-xl">{post.frontmatter.title}</div>
            </div>
            <div className="pt-2">
              <div className="text-sm">{post.frontmatter.description}</div>
            </div>
          </div>
        </Link>
        <div className="px-6">
          {post.frontmatter.tags && post.frontmatter.tags.map((tag) => (
            <Link 
              to={`/tags/${kebabCase(tag)}/`}
              className="inline-block border border-gray-300 hover:border-gray-600 py-1 px-2 text-sm text-gray-700 font-light mr-2 mt-2">{tag}
            </Link>
          ))}
        </div>
      </div>
    )
  }
}
export default BlogPost