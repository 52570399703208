import React from 'react'
import BlogPost from './BlogPost'

class BlogPosts extends React.Component {
  render() {
    const { edges: posts } = this.props.blogPosts

    return (
      <div className="max-w-postpage m-auto">
        {posts && posts.map(({ node: post }) => (
          <BlogPost 
            post={post}
          ></BlogPost>
        ))}
      </div>
    )
  }
}

export default BlogPosts